
// tslint:disable:variable-name
import { Modal } from 'ant-design-vue';
import { Options, Vue } from 'vue-class-component';
import {
  VehicleListService,
  GetModelParam,
  VehicleSimple,
} from './vehicle-list.service';
@Options({
  components: {
    'a-modal': Modal,
  },
})
export default class VehicleList extends Vue {
  public industrys: Array<{ industry: string }> = [];
  public license_card: string = ''; // 车牌号
  public vehicle_ids: string = ''; // 车辆ID
  public license_color: string = ''; // 颜色
  public company_name: string = ''; // 企业
  public industry: string = ''; // 车辆所属行业
  public form_supplier: string = ''; // 设备商
  public form_type: string = ''; // 设备版本
  public form_industry: string = '';
  public device_supplier: string = ''; // 设备商
  public device_version: string = ''; // 设备版本
  public device_type: string = ''; // 设备型号
  public update_time: string = ''; // 时间
  public height = 300;
  public suppliers: Array<{ supplier: string }> = [];
  public types: Array<{ type: string }> = [];
  public versions: Array<{ version: string }> = [];
  public nullVisible: boolean = false;
  public form_versinon: string = '';

  // public induStrys :
  public pagination = {
    current: 2,
    pageSize: 10,
    total: 100,
  };
  public columns = [
    {
      title: '车牌号',
      width: 150,
      dataIndex: 'license_card',
      key: '1',
      // fixed: 'left',
    },
    {
      title: '车牌颜色',
      width: 100,
      dataIndex: 'license_color',
      key: '2',
      // fixed: 'left',
    },
    { title: '所属行业', dataIndex: 'industry', key: '3', width: 150 },
    { title: '所属企业', dataIndex: 'company_name', key: '4', width: 150 },
    { title: '设备商', dataIndex: 'device_supplier', key: '5', width: 150 },
    { title: '设备型号', dataIndex: 'device_type', key: '9', width: 150 },

    {
      title: '终端软件版本号',
      dataIndex: 'device_version',
      key: '6',
      width: 150,
    },
    {
      title: '参数下发更新时间',
      dataIndex: 'device_update_time',
      key: '7',
      width: 200,
    },
    {
      title: '操作',
      key: '8',
      dataIndex: 'vehicle_ids',
      width: 200,
      slots: { customRender: 'action' },
    },
  ];
  public list: VehicleSimple[] = [];
  public text = 'text';

  public selectedRowKeys: number[] = [];
  // 按行业下发
  public visible: boolean = false;
  // 设备型号下拉框是否禁用
  public typeDisabled: boolean = true;
  // 软件版本下拉框是否禁用
  public device_version_disabled: boolean = true;

  public form_type_dis: boolean = true;

  public form_version_dis: boolean = true;
  public confirmLoading: boolean = false;
  public confirmError: boolean = false;

  public param: GetModelParam = new GetModelParam();
  public async formSupplierChange() {
    if (!this.form_supplier) {
      this.form_type = '';
      this.form_type_dis = true;
      this.form_versinon = '';
      this.form_version_dis = true;
      return;
    }
    const data: Array<{
      type: string;
    }> = await this.$http.get(`${process.env.VUE_APP_API}/devices/types`, {
      supplier: this.form_supplier,
    });
    this.types = data;
    this.form_type_dis = false;
    this.form_type = '';
  }

  public async formTypeChange() {
    if (!this.form_type) {
      this.form_versinon = '';
      this.form_version_dis = true;
      return;
    }
    const data: Array<{ version: string }> = await this.$http.get(
      `${process.env.VUE_APP_API}/devices/versions`,
      {
        supplier: this.form_supplier,
        type: this.form_type,
      },
    );
    this.versions = data;
    this.form_version_dis = false;
  }

  // 当设备商反生改变时请求设备型号接口 禁用取消
  public async devSupplierChange() {
    if (!this.device_supplier) {
      this.device_version = '';
      this.device_type = '';
      this.typeDisabled = true;
      this.device_version_disabled = true;
      return;
    }
    const data: Array<{
      type: string;
    }> = await this.$http.get(`${process.env.VUE_APP_API}/devices/types`, {
      supplier: this.device_supplier,
    });
    this.types = data;
    this.device_version = '';
    this.device_type = '';
    // 取消设备型号下拉框的禁用
    this.typeDisabled = false;
    this.device_version_disabled = true;
  }

  public nullHandleOk() {
    this.nullVisible = false;
  }

  // 道理同上
  public async typeChange() {
    if (!this.device_type) {
      this.device_version = '';
      this.device_version_disabled = true;
      return;
    }
    // 接口调用
    const data: Array<{ version: string }> = await this.$http.get(
      `${process.env.VUE_APP_API}/devices/versions`,
      {
        supplier: this.device_supplier,
        type: this.device_type,
      },
    );
    this.versions = data;
    this.device_version = '';
    // 取消软件版本下拉框的禁用
    this.device_version_disabled = false;
  }
  // vehicles/industrys
  public async SubordinateToindustry() {
    const data: Array<{ industry: string }> = await this.$http.get(
      `${process.env.VUE_APP_API}/vehicles/industrys`,
      {},
    );
    this.industrys = data;
  }
  // 参数查询
  public async queryParameters(id: string) {
    // 发起查询请求  调接口就ok "vehicle_ids"
    const param = {
      vehicle_ids: [id],
    };
    this.$base.loading.show();
    const http = await this.$http
      .post(`${process.env.VUE_APP_API}/vehicles/config/search`, param)
      .then((r) => {
        this.$base.success('发送成功');
      })
      .catch((e) => {
        this.$base.error(e);
      });
    this.$base.loading.close();
  }
  // 参数下发
  public queryUp(id: string, vehicleIds: string) {
    // 和批量设置参数内容跳转一样
    this.$router.push({
      path: '/public/presupposition-detail',
      query: { id, vehicleIds },
    });
  }
  // 下发请求中
  public mounted() {
    this.$storage.get<number>('height-vehicle-list').then((value) => {
      this.height = value ? value : 300;
    });
    window.onresize = this.checkHeight;
    this.SubordinateToindustry();
    this.getSuppliers();
    this.search();
  }
  // 查询
  public search() {
    this.param.license_card = this.license_card; // 车牌号
    this.param.company_name = this.company_name; // 所属企业
    this.param.industry = this.form_industry; // 所属行业
    this.param.device_supplier = this.form_supplier; // 设备商
    this.param.device_type = this.form_type; // 设备型号
    this.param.device_version = this.form_versinon; //
    this.pagination.current = 1;
    this.fresh(this.pagination);
  }
  // 刷新
  public async fresh(page: {
    current: number;
    pageSize: number;
    total: number;
  }) {
    this.pagination = page;
    this.param.page_number = page.current;
    this.param.page_size = page.pageSize;
    this.$base.loading.show();
    // tslint:disable-next-line:max-line-length
    const http = await this.$http
      .get<{ list: VehicleSimple[]; total: number }>(
        `${process.env.VUE_APP_API}/vehicles`,
        this.param,
      )
      .then(
        (r) => {
          this.list = r.list;
          this.pagination.total = r.total;
          this.checkHeight();
        },
        (e) => {
          this.$base.error(e);
        },
      );
    this.checkHeight();
    this.$base.loading.close();
  }
  public checkHeight() {
    const table = document.querySelector('#table');
    this.height = table ? table.clientHeight - 64 - 54 - 10 : 300;
    this.$storage.save('height-vehicle-list', this.height);
  }
  public active(val: number) {
    console.log('active', val);
    // console.log(this['$storage'])
  }
  // 表格改变
  public onSelectChange(selectedRowKeys: number[]) {
    this.selectedRowKeys = selectedRowKeys;
  }
  public async supplier() {
    const http = await this.$http.get(
      `${process.env.VUE_APP_API}/devices/suppliers`,
      this.param,
    );
  }
  public async send() {
    if (!this.selectedRowKeys.length) {
      return this.$base.error('请先选择车辆');
    }

    let key: number | undefined;
    const vehicleIds: number[] = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.selectedRowKeys.length; i++) {
      const element = this.selectedRowKeys[i];
      const target = this.list[element];
      // console.log(key, target.id)
      if (key === undefined) {
        key = target.device_id;
      } else {
        if (key !== target.device_id) {
          return this.$base.error('所选择的车辆设备号不一致');
        }
      }
      vehicleIds.push(target.id as number);
    }
    const target2 = this.list[this.selectedRowKeys[0]];
    this.$router.push({
      path: '/public/presupposition-detail',
      query: { id: target2.device_id, vehicleIds: vehicleIds.toString() },
    });
  }

  public async getSuppliers() {
    const data: Array<{ supplier: string }> = await this.$http.get(
      `${process.env.VUE_APP_API}/devices/suppliers`,
      {},
    );
    this.suppliers = data;
  }

  public sendByIndustry() {
    this.visible = true;
  }
  public async handleOk() {
    this.confirmLoading = true;
    const param = {
      device_supplier: this.device_supplier,
      device_type: this.device_type,
      device_version: this.device_version,
      page_number: 1,
      page_size: 1,
    };
    const http = await this.$http
      .get<{ list: VehicleSimple[] }>(
        `${process.env.VUE_APP_API}/devices/models`,
        param,
      )
      .then(
        (r) => {
          const data = r.list[0];
          if (!data) {
            this.confirmLoading = false;
            this.cancel();
            this.confirmError = true;
            this.nullVisible = true;
            return;
          }
          const { id, industry } = data;
          this.$router.push({
            path: '/public/presupposition-detail',
            query: {
              id,
              industry,
            },
          });
          this.confirmLoading = false;
          this.cancel();
        },
        (e) => {
          this.$base.error(e);
        },
      );
  }
  public cancel() {
    this.device_supplier = '';
    this.device_type = '';
    this.device_version = '';
    this.industry = '';
    this.typeDisabled = true;
    this.device_version_disabled = true;
    this.visible = false;
  }
}
