import { AxiosPromise } from 'axios';
import Http from '@/core/http.service';
export class GetModelParam {
    public license_card: string | undefined;
    public company_name: string | undefined;
    public device_supplier:string|undefined;
    public industry: string | undefined;
    public device_type:string|undefined;
    public page_number: number | undefined;
    public page_size: number | undefined;
    public vehicle_ids :string |undefined;
    public device_version :string |undefined;
}
export class VehicleSimple {
    public id: number | undefined;
    public license_card: string | undefined;
    public company_name: string | undefined;
    public industry: string | undefined;
    public device_type:string|undefined;
    public device_id?: number
    public device_version:string|undefined;
    public device_supplier:string|undefined;
    public device_update_time:string|undefined;
    public license_color:string|undefined;

}
export class VehicleListService {
    public baseUrl: string = process.env.VUE_APP_API;
    // tslint:disable-next-line:member-access
    $http = Http;
    public getModelList(param: GetModelParam): AxiosPromise<{list: VehicleSimple[], total: number}> {
        return this.$http.get(`${process.env.VUE_APP_API}/vehicles`, param);
    }
}

export class ParamArray {
    public license_card: string | undefined = ''; //车牌号
    public company_name: string | undefined = ''; // 所属企业
    public industry: string | undefined = ''; // 所属行业
    public supplier: string | undefined = ''; //设备商
    public device_type: string | undefined = ''; // 设备型号
}
